import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import {
    PRIMARY, SECONDARY, WHITE, OBERMAIN_BLACK, OBERMAIN_BORDER, OBERMAIN_GOLD,
} from '@theme_color';
import { FONT_24, FONT_DEFAULT, FONT_REGULAR } from '@theme_typography';
import { BREAKPOINTS } from '@theme_vars';

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
        },
        error: {
            main: red.A400,
        },
        background: {
            default: WHITE,
        },
    },
    breakpoints: {
        values: {
            ...BREAKPOINTS,
        },
    },
    overrides: {
        MuiGrid: {
            root: {
                padding: 0,
                margin: 0,
            },
        },
        MuiTextField: {
            root: {
                ...FONT_REGULAR,
            },
        },
        MuiTypography: {
            h1: {
                ...FONT_24,
                ...FONT_DEFAULT,
            },
            root: {
                ...FONT_REGULAR,
                color: OBERMAIN_BLACK,
            },
        },
        MuiRadio: {
            root: {
                color: OBERMAIN_BORDER,
                '&$checked': {
                    color: `${OBERMAIN_BLACK} !important`,
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: OBERMAIN_BORDER,
                '&$checked': {
                    color: `${OBERMAIN_BLACK} !important`,
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                ...FONT_REGULAR,
            },
            root: {
                marginBottom: -15,
            },
        },
        MuiDrawer: {
            paperAnchorRight: {
                background: 'transparent',
                boxShadow: 'none',
            },
        },
        MuiIcon: {
            root: {
                color: PRIMARY,
            },
        },

        // style obermain
        MuiDivider: {
            root: {
                backgroundColor: `${OBERMAIN_BORDER}`,
            },
        },
        MuiButton: {
            root: {
                '&.Mui-disabled': {
                    opacity: '0.7',
                    borderRadius: 3,
                },
            },
            outlined: {
                borderRadius: 3,
                color: `${WHITE} !important`,
                backgroundColor: OBERMAIN_BLACK,
                ...FONT_DEFAULT,
                border: `1px solid ${OBERMAIN_BLACK}`,
                '& p, & span': {
                    color: WHITE,
                    fontSize: 12,
                },
                '&:hover': {
                    color: `${WHITE} !important`,
                    backgroundColor: `${OBERMAIN_GOLD} !important`,
                    border: `1px solid ${OBERMAIN_GOLD} !important`,
                    '& p, & span': {
                        color: WHITE,
                    },
                },
            },
            contained: {
                borderRadius: 3,
                color: `${WHITE} !important`,
                backgroundColor: `${OBERMAIN_BLACK} !important`,
                ...FONT_DEFAULT,
                border: `1px solid ${OBERMAIN_BLACK} !important`,
                '& p, & span': {
                    color: WHITE,
                    fontSize: 12,
                    fontWeight: '700',
                },
                '&:hover': {
                    color: `${WHITE} !important`,
                    backgroundColor: `${OBERMAIN_GOLD} !important`,
                    border: `1px solid ${OBERMAIN_GOLD} !important`,
                    '& p, & span': {
                        color: WHITE,
                    },
                },
            },
            text: {
                '&:hover': {
                    backgroundColor: 'transparent !important',
                },
            },
        },
        MuiList: {
            root: {
                '& .item-shipping': {
                    display: 'block',
                    '& .shipping-courier': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '5px 0',
                    },
                },
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 18,
                ...FONT_DEFAULT,
                color: OBERMAIN_BLACK,
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: 3,
                marginTop: '25px !important',
                border: `1px solid ${OBERMAIN_BORDER}`,
                '&:focus': {
                    border: `1px solid ${OBERMAIN_BLACK}`,
                },
                '& input': {
                    height: 40,
                    borderRadius: 3,
                    padding: '0 15px !important',
                    ...FONT_DEFAULT,
                    fontSize: 14,
                },
                '&:before, &:after': {
                    display: 'none',
                },
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            },
        },
        // end style obermain
    },
});

export default theme;
